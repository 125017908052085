.heroContent {
  display: flex;
  max-width: 1245px;
  margin-top: 20px;
  margin-bottom: 40px;
  flex-direction: row;
  justify-content: space-between;
}

.titleWrapper {
  margin-top: 45px;
  max-width: 700px;
  color: #fff;
}

.titleWrapper .title {
  margin-bottom: 10px;
  color: var(--purple);
  font-weight: 500;
  font-size: 7rem;
}

.titleWrapper .text {
  color: var(--purple);
  font-size: 3.2rem;
  font-weight: 300;
  line-height: 4.3rem;
}

.signUpWrapper {
  width: 400px;
  height: 495px;
  margin-top: -60px;
  padding: 40px 30px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 4px;
  /* box-shadow: 0 2px 40px 10px rgba(203, 185, 185, 0.5); */
  color: #3f3356;
}

.signUpWrapper .title {
  margin-bottom: 30px;
  color: #000;
  font-size: 2.6rem;
  font-weight: 600;
}

.signUpWrapper .smallText {
  position: relative;
  top: -6px;
  left: 5px;
  color: #948e99;
  font-size: 1.2rem;
}

.signUpWrapper .textbox {
  width: 100%;
  margin-bottom: 20px;
}

.toolbarContainer {
  margin-top: 60px;
  margin-bottom: 50px;
}

.searchStyles {
  width: 450px;
  margin: 10px 0 50px;
}
