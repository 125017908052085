.detailsWrapper {
  position: relative;
  margin-bottom: 40px;
}

.label {
  display: inline-block;
  margin-bottom: 20px;
  color: var(--black);
  font-size: 2rem;
  font-weight: 500;
}


.value {
  color: var(--purple);
  font-size: 1.8rem;
  font-weight: 500;
}