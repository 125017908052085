.wrapper {
  margin-right: 4px;
}
.wrapper:last-child {
  margin-right: 0;
}

.halfCircle {
  display: inline-block;
  width: 8px;
  height: 16px;
  border-radius: 16px 0 0 16px;
}

.halfCircle.halfCircle--secondHalf {
  border-radius: 0 16px 16px 0;
}
