@import '../../../components/shared/styles/colors.module.css';

.label {
  display: inline-block;
  margin-bottom: 20px;
  color: var(--black);
  font-size: 1.8rem;
  font-weight: 500;
}

.ratingWrapper {
  display: flex;
  justify-content: space-between;
  width: 200px;
  margin-bottom: 40px;
}

.scoreButton {
  composes: buttonNoStyle from '../../../components/shared/styles/components.module.css';
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 2px solid var(--gray-light);
  color: var(--gray-dark);
  font-size: 1.8rem;
  font-weight: 500;
}

.scoreButton:hover {
  background-color: var(--white-gray);
}

.active,
.active:hover {
  color: var(--white);
  background-color: var(--blue);
  border-color: var(--blue);
}

.radioWrapper {
  display: flex;
  margin-bottom: 30px;
}

.radio {
  margin-right: 40px;
}

.textarea {
  max-width: 600px;
  height: 190px;
  margin-bottom: 40px;
  font-size: 1.6rem;
  font-weight: 500;
}

.dropdown {
  max-width: 400px;
  color: var(--black);
}

.textboxWrapper {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin-top: 40px;
}

.textbox {
  margin-bottom: 40px;
  color: var(--black);
}

.topicsTooltipWrapper {
  position: relative;
}

.topicsTooltipLabel {
  composes: questionMarkTooltipLabel from '../../../components/shared/styles/components.module.css';
  left: 17ch;
}

.topicsTooltip {
  width: 165px;
}
