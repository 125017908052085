@import '../../../components/shared/styles/colors.module.css';

.wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.reviewsWrapper {
  width: 50%;
  max-width: 420px;
  margin-bottom: 60px;
}

.title {
  padding-bottom: 5px;
  border-bottom: 1px solid var(--gray-lightest);
  color: var(--black);
  font-size: 2.2rem;
  font-weight: 600;
}

.ratingItem {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-right: 0;
  padding-left: 0;
}

.circlesWrapper {
  width: 120px;
}

.quote {
  margin-bottom: 25px;
  padding-left: 12px;
  color: var(--purple);
  font-size: 1.8rem;
  font-weight: 400;
  font-style: italic;
}

.quote::before,
.quote::after {
  content: '"';
}

.noResults {
  composes: noResults from '../../company-details-page/company-ratings/CompanyRatings.module.css';
}
