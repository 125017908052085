.ratingItem {
  margin-top: 40px;
  padding: 0;
}

.circlesWrapper {
  width: 150px;
}

.reviewWrapper {
}

.reviewWrapper .button {
  position: absolute;
  display: flex;
  align-items: center;
  margin-top: -28px;
  margin-left: 165px;
  height: 24px;
  padding: 3px 7px;
}

.textarea {
  box-sizing: border-box;
  min-height: 0;
  height: 0;
  margin-top: 20px;
  padding: 0;
}

.textarea.expanded {
  height: 95px;
  padding: 10px 18px;
}

.textarea:not(.expanded) {
  border: none;
}
