.columnsWrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 40px;
}

.leftColumn,
.rightColumn {
  width: 335px;
}

.yearDropdownWrapper {
  position: relative;
  width: 220px;
}

.yearToolTipLabel {
  position: absolute;
  top: -9px;
  left: 4.3ch;
  padding: 3px;
  color: var(--gray);
  font-size: 1.4rem;
  font-weight: 600;
  cursor: default;
}

.yearToolTip {
  width: 100px;
}

.radioGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.textarea {
  height: 95px;
}
