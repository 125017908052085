@import '../styles/colors.module.css';

.label {
  display: block;
  margin-bottom: 8px;
  color: var(--black);
  font-size: 1.8rem;
  font-weight: 500;
}

.textarea {
  width: 100%;
  min-width: 335px;
  height: 100%;
  min-height: 95px;
  padding: 10px 18px;
  border: 1px solid var(--gray-light);
  border-radius: 6px;
  color: var(--black);
  font-family: inherit;
  font-size: 1.8rem;
  font-weight: 400;
  resize: none;
}

.textarea::placeholder {
  color: var(--gray);
}

.textarea.resizable {
  resize: auto;
}
