@import '../styles/colors.module.css';

.wrapper {
  width: 100%;
}

.dropdownWrapper {
  position: relative;
  width: 100%;
  height: 48px;
}

.dropdown {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%;
  padding: 13px 20px;
  box-sizing: border-box;
  background: var(--white) url('../../../assets/icons/arrow-dropdown.svg') no-repeat center right 20px;
  border: 1px solid var(--gray-light);
  border-radius: 6px;
  color: var(--purple);
  font-size: 1.8rem;
  font-weight: 500;
  text-align: left;
  text-transform: capitalize;
}

.select {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0;
  text-transform: capitalize;
  cursor: pointer;
}

.select:focus + .dropdown {
  padding: 11px 18px;
  border: 3px solid var(--blue);
}

.label {
  display: inline-block;
  margin-bottom: 8px;
  color: var(--black);
  font-size: 1.8rem;
  font-weight: 500;
}
