:root {
  --black: #000000;
  --black-light: #1d2739;
  --blue: #6979f8;
  --blue-light: #7585fd;
  --blue-faded: #e5e7fa;
  --blue-dark: #606ed9;
  --gray: #a7a1ad;
  --gray-dark: #9a979c;
  --gray-faded: #ebeff3;
  --gray-light: #d0d4d8;
  --gray-lightest: #ecebed;
  --green: #00c48c;
  --green-faded: #d5f2ea;
  --purple: #3f3356;
  --red: #ff647c;
  --red-faded: #fbe4e8;
  --yellow: #ffcf5c;
  --yellow-faded: #f7f1e1;
  --white: #ffffff;
  --white-gray: #f6f6f6;
}
