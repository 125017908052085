.header {
  position: relative;
  height: 80px;
  z-index: 9;
  color: pink;
}

.navigationWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: auto;
  height: 33px;
  margin-top: 40px;
}

.accountWrapper {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  align-items: center;
}

.signUp {
  color: var(--white);
  font-size: 1.6rem;
  font-weight: 500;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
}

.signUp:hover {
  text-decoration: underline;
}

.login {
  composes: buttonPrimary from '../shared/styles/components.module.css';
  margin-left: 40px;
}

.settingsWrapper {
  position: relative;
  margin-left: auto;
}

.settingsButton {
  composes: signUp;
  max-height: 30px;
  padding: 0 5px;
}

.settingsArrow {
  margin-left: 10px;
}
