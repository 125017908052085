@import '../../../components/shared/styles/colors.module.css';

.label {
  margin-bottom: 15px;
  color: var(--gray);
  font-size: 1.5rem;
  font-weight: 500;
}

.text {
  margin-bottom: 10px;
  color: var(--purple);
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.7rem;
}

.detailsWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
}

.leftColumn,
.rightColumn {
  width: 50%;
}

.detailWrapper {
  margin-top: 40px;
  color: var(--purple);
}

.textWrapper {
  font-size: 1.5rem;
  color: var(--purple);
}

.textWrapper .detail {
  margin-bottom: 0;
}

.icon {
  margin-right: 8px;
}

.link:hover {
  text-decoration: underline;
}

.social {
  margin-bottom: 15px;
}
