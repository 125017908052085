@import '../../../components/shared/styles/colors.module.css';

.wrapper {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  width: 100%;
}

.row {
  flex-direction: row;
  align-items: center;
}

.column {
  flex-direction: column;
}

.wrapper.hover:hover {
  background-color: var(--white-gray);
}

.nameWrapper {
  display: flex;
  align-items: center;
}

.column .nameWrapper {
  margin-bottom: 10px;
}

.icon {
  margin-right: 10px;
  font-size: 2.2rem;
}

.name {
  color: var(--black);
  font-size: 1.8rem;
  font-weight: 500;
  text-transform: capitalize;
}

.value {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.tooltip {
  padding-right: 20px;
  padding-left: 20px;
  white-space: nowrap;
}
