@import '../../components/shared/styles/colors.module.css';

.content {
  max-width: 840px;
  margin-top: 300px;
}

.stepWrapper {
  max-width: 335px;
  margin: 0 auto 50px;
}

.footerButtonsWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 100px;
}

.footerCancel {
  composes: buttonNoStyle from '../../components/shared/styles/components.module.css';
  min-width: auto;
  color: var(--gray-dark);
  font-size: 1.6rem;
  font-weight: 500;
}

.footerButtonsWrapper .nextWrapper {
  position: relative;
  margin-left: 50px;
}

.nextButton {
  composes: buttonPrimary from '../../components/shared/styles/components.module.css';
  min-width: 135px;
}

.submitButton {
  composes: nextButton;
  margin-left: 50px;
}

.tooltip {
  width: 160px;
}
