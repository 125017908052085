.seeMoreButton {
  composes: buttonNoStyle from '../../../../../components/shared/styles/components.module.css';
  display: flex;
  align-items: center;
  margin-top: -10px;
  margin-bottom: 20px;
  padding: 5px 10px 5px 0;
  color: var(--gray);
  font-size: 1.6rem;
  font-weight: 500;
}

.seeMoreButton:hover {
  color: var(--purple);
}

.seeMoreButton .icon {
  margin-left: 10px;
}
