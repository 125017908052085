.label {
  display: block;
  margin-bottom: 8px;
  color: var(--black);
  font-size: 1.8rem;
  font-weight: 500;
}

.textbox {
  min-width: 240px;
  width: 100%;
  height: 48px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 1px solid #d0d4d8;
  border-radius: 6px;
  color: #3f3356;
  font-size: 1.8rem;
  font-weight: 500;
}

.textbox--hasIcon {
  padding-left: 43px;
}

.textbox::placeholder {
  color: #a7a1ad;
  font-weight: 400;
  text-transform: capitalize;
}

.textboxContainer {
}

.textboxContainer .icon {
  position: absolute;
  margin-top: 14px;
  margin-left: 15px;
}
