@import '../../../components/shared/styles/colors.module.css';

.wrapper {
  position: absolute;
  width: 910px;
  left: 50%;
  margin-top: -200px;
  transform: translateX(-50%);
}

.title,
.text,
.link {
  text-align: center;
}

.title {
  margin-bottom: 30px;
  color: var(--black);
  font-size: 3.8rem;
  font-weight: 600;
}

.icon {
  display: inline-block;
  margin-left: 10px;
}

.text {
  color: var(--black);
  font-size: 2.2rem;
  font-weight: 500;
  line-height: 3.5rem;
}

.link {
  margin-top: 15px;
  color: var(--blue);
  font-size: 2.2rem;
  font-weight: 500;
}

.link:hover {
  text-decoration: underline;
}
