@import-normalize;

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-size: 16px;
  font-family: 'Montserrat', 'Roboto', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1,
h2,
h3,
p,
dd,
dt,
dl {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

input,
button {
  font-family: 'Montserrat';
}

button {
  border: none;
}

ul {
  padding: 0;
}

li {
  list-style: none;
}

hr {
  margin: 0;
  border: none;
}
