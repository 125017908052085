@import '../../../components/shared/styles/colors.module.css';

:root {
  --pointerWidth: calc(14px / 2);
  --pointerHeight: 6px;
}

.accountMenu {
  position: absolute;
  left: 50%;
  min-width: 200px;
  transform: translateX(-50%);
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: 0 1px 3px #00000015;
  color: var(--purple);
  font-size: 1.6rem;
  font-weight: 500;
}

.accountMenu::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -8px;
  border-width: 0 var(--pointerWidth) var(--pointerHeight) var(--pointerWidth);
  border-color: transparent transparent var(--white) transparent;
  border-style: solid;
  -webkit-transform: rotate(360deg);
}

.accountMenuItem:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.item {
  display: flex;
  align-items: center;
  width: 100%;
  height: 45px;
  padding: 0 25px;
  box-sizing: border-box;
  border-radius: 0;
  color: var(--purple);
}

.item:not(.username) {
  text-transform: capitalize;
}

.accountMenuItem:first-child .item {
  padding-top: 20px;
  padding-bottom: 15px;
}

.accountMenuItem:last-child .item {
  margin-top: 15px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.item:not(.username):hover {
  background-color: var(--gray-faded);
}

.item.username {
  justify-content: center;
  color: var(--black-light);
}

.itemIcon {
  margin-right: 10px;
}
