@import '../styles/colors.module.css';

.wrapper {
  margin-top: 75px;
}

.hero,
.banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 360px;
}

.hero {
  background-color: rgba(85, 85, 85, 0.15);
  backdrop-filter: blur(5px);
  text-align: center;
}

.banner {
  object-fit: cover;
}

.logo {
  display: block;
  width: 100px;
  height: 100px;
  margin: 0 auto 15px;
  object-fit: cover;
  border-radius: 50%;
}

.companyName,
.slogan {
  color: var(--white);
  font-size: 5.2rem;
  font-weight: 500;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

.slogan {
  max-width: 900px;
  margin: 15px auto 0;
  font-size: 3.2rem;
  font-weight: 300;
}
