@import '../../../components/shared/styles/colors.module.css';

.ratingItem {
  width: 100%;
}

.circlesWrapper {
  width: 120px;
}

.noResults {
  margin-top: 70px;
  color: var(--black);
  font-size: 2.2rem;
  font-weight: 500;
  text-align: center;
}
