@import '../../../styles/colors.module.css';

.wrapper {
  width: 700px;
  height: 300px;
  text-align: center;
  color: var(--black);
}

.title {
  margin-bottom: 30px;
  font-size: 3rem;
  font-weight: 500;
  text-transform: capitalize;
}

.text {
  font-size: 2.2rem;
  font-weight: 400;
  margin-bottom: 25px;
}

.searchWrapper {
  width: 460px;
  margin: 0 auto;
}
