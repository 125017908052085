@import '../../styles/colors.module.css';

.wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.half {
  composes: buttonNoStyle from '../../styles/components.module.css';
  composes: halfCircle from '../rating-half-circle/RatingHalfCircle.module.css';
  background-color: var(--blue-faded);
}

.half:hover {
  background-color: var(--blue);
}

.right {
  composes: halfCircle--secondHalf from '../rating-half-circle/RatingHalfCircle.module.css';
}

.active {
  background-color: var(--blue);
}

.tooltip {
  width: 30px;
}
