@import '../../../shared/styles/colors.module.css';

:root {
  --small: 5px;
  --medium: 7px;
  --animationLength: 0.7s;
}

.wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: space-between;
  transform: translate(-50%, -50%);
}

.dot {
  background-color: var(--blue);
  transform: translateY(-10px);
  animation-duration: var(--animationLength);
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.dot:nth-child(1) {
  animation-delay: 0;
}

.dot:nth-child(2) {
  animation-delay: calc(var(--animationLength) / 3.5);
}

.dot:nth-child(3) {
  animation-delay: calc(var(--animationLength) / 1.75);
}

.wrapper.small {
  width: 21px;
}

.dot.small {
  width: var(--small);
  height: var(--small);
  border-radius: var(--small);
  animation-name: bounce-small;
}

.wrapper.medium {
  width: 29px;
  height: var(--medium);
}

.dot.medium {
  width: var(--medium);
  height: var(--medium);
  border-radius: var(--medium);
  animation-name: bounce;
}

@keyframes bounce {
  0% {
    transform: translateY(-10px);
  }

  80% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes bounce-small {
  0% {
    transform: translateY(-5px);
  }

  80% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-5px);
  }
}
