@import '../styles/colors.module.css';

.circle--default {
  background-color: var(--blue);
}

.circle--red {
  background-color: var(--red);
}

.circle--yellow {
  background-color: var(--yellow);
}

.circle--green {
  background-color: var(--green);
}

.circleFaded--default {
  background-color: var(--blue-faded);
}

.circleFaded--red {
  background-color: var(--red-faded);
}

.circleFaded--yellow {
  background-color: var(--yellow-faded);
}

.circleFaded--green {
  background-color: var(--green-faded);
}
