.banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.6;
}

.contentWrapper {
  position: relative;
  height: 160px;
  padding: 20px;
  background-color: rgba(30, 30, 30, 0.1);
  backdrop-filter: blur(3px);
}

.avatar {
  width: 70px;
  height: 70px;
  margin-bottom: 12px;
  border-radius: 50%;
  object-fit: cover;
}

.name {
  margin-bottom: 6px;
  color: #fff;
  font-size: 2.5rem;
  font-weight: 600;
  text-transform: capitalize;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

.slogan {
  max-height: 40px;
  overflow: hidden;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.slogan:first-letter {
  text-transform: capitalize;
}

.footer {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  flex-direction: row;
  padding: 10px 20px 15px;
  box-sizing: border-box;
  background-color: #fff;
}

.overallRating {
  color: #3f3356;
  font-size: 3.6rem;
  font-weight: 800;
}

.overallRating .smallText {
  color: #9a979c;
  font-size: 1.8rem;
  font-weight: 400;
}

.totalReviews {
  color: #9a979c;
  font-size: 1.2rem;
}

.labelsWrapper {
  width: 50%;
  margin-top: 10px;
  margin-left: 25px;
}

.icon {
  margin-right: 7px;
}

.label {
  display: flex;
  align-items: center;
  height: 17px;
  margin-bottom: 10px;
  overflow: hidden;
  color: #3f3356;
  font-size: 1.2rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}
