.wrapper {
  width: 900px;
  text-align: center;
}

.modalTitle {
  margin-bottom: 35px;
  color: var(--black);
  font-size: 3rem;
  font-weight: 500;
}

.modalText {
  margin: 0 auto;
  max-width: 530px;
  color: var(--black);
  font-weight: 400;
  font-size: 2.2rem;
  line-height: 3.5rem;
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  width: 280px;
  margin: 60px auto 0;
}

.buttonWrapper .submit {
  width: 160px;
}

.buttonWrapper .submit,
.buttonWrapper .cancel {
  height: 48px;
  font-size: 1.8rem;
}
