@import '../styles/colors.module.css';

.button {
  position: relative;
  composes: buttonPrimary from '../styles/components.module.css';
}

.disabled {
  composes: buttonDisabled from '../styles/components.module.css';
}

.button,
.disabled {
  min-width: 135px;
}

.noStyle {
  composes: buttonNoStyle from '../styles/components.module.css';
  padding: 0;
  min-width: 0;
  background: none;
  color: var(--gray-dark);
}

.loadingWheel {
  margin-top: -9px;
  margin-left: -8px;
}
