@import '../styles/colors.module.css';

.wrapper {
  position: relative;
  display: flex;
  align-items: flex-start;
}

.radioWrapper {
  position: relative;
}

.radioInput {
  position: relative;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}

.radioInput + .radio {
  position: absolute;
  z-index: 0;
  box-sizing: border-box;
  top: 0;
  left: 0;
  border: 1px solid var(--blue);
  border-radius: 50%;
}

.radioInput + .radio::after {
  content: '';
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background-size: 0 0;
  border: 2px solid var(--white);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.1s, height 0.1s;
}

.radioInput:checked + .radio::after {
  width: 100%;
  height: 100%;
  background-color: var(--blue);
}

.label {
  margin-left: 13px;
  color: var(--black);
  font-size: 1.6rem;
  font-weight: 500;
  cursor: pointer;
}
