@import '../styles/colors.module.css';

:root {
  --itemVerticalPadding: 10px;
}

.wrapper {
  position: relative;
  width: 100%;
}

.textbox {
  width: 100%;
  z-index: 2;
}

.list {
  position: absolute;
  z-index: 2;
  width: 100%;
  margin: 8px 0 0;
  background-color: var(--white);
  box-shadow: 0 3px 10px #6b6b6b28;
  border-radius: 6px;
  overflow: hidden;
}

.dots {
  margin-top: 5px;
}

.link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: var(--itemVerticalPadding) 0;
}

.link:not(.noResults):hover {
  background-color: var(--gray-lightest);
}

.noResultsIcon {
  position: relative;
  top: 5px;
  margin-left: 10px;
  font-size: 2.5rem;
}

.icon {
  max-width: 40px;
  height: 100%;
  margin-left: 15px;
  object-fit: cover;
}

.companyName {
  margin-left: 15px;
  color: var(--purple);
  font-size: 1.8rem;
  font-weight: 500;
}
