@import '../../../components/shared/styles/colors.module.css';

.loading {
  position: relative;
  left: 0;
  margin: 50px auto 150px;
}

.icon {
  display: inline-block;
  margin-right: 12px;
}

.ratingWrapper {
  position: relative;
  margin-bottom: 30px;
}

.ratingItem {
  padding: 0;
}

.circlesWrapper {
  width: 150px;
}

.reviewIndicator {
  position: absolute;
  bottom: 23px;
  left: 155px;
  width: 7px;
  height: 7px;
  border-radius: 100%;
  background-color: var(--blue);
}

.categoryTooltip {
  left: 2px;
  width: 90px;
}

.columnsWrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 40px;
}

.leftColumn,
.rightColumn {
  width: 335px;
}

.overallScore {
  margin-top: -40px;
}

.overallScore .value {
  margin-top: -5px;
  color: var(--purple);
  font-size: 2.8rem;
  font-weight: 600;
}

.overallScore .icon {
  font-size: 2.4rem;
}

.title {
  margin-bottom: 10px;
  font-size: 2rem;
  font-weight: 600;
}

.reviewFooter {
  margin-top: 20px;
  margin-bottom: 30px;
}

.overallReview {
  line-height: 2.8rem;
}

.bold {
  font-weight: 600;
}

.questionMarkTooltipLabel {
  composes: questionMarkTooltipLabel from '../../../components/shared/styles/components.module.css';
  left: auto;
}

.questionMarkTooltip {
  width: 250px;
}
