.circle {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 4px;
  border-radius: 8px;
  background-color: pink;
}

.circle:last-child {
  margin-right: 0;
}
