@import '../../../../../components/shared/styles/colors.module.css';

.wrapper {
  text-align: center;
}

.upVote {
  composes: buttonNoStyle from '../../../../../components/shared/styles/components.module.css';
}

.icon {
  width: 20px;
  height: auto;
  padding: 7px 5px 4px;
}

.totalVotes {
  margin-top: 3px;
  color: var(--gray);
  font-size: 1.4rem;
  font-weight: 500;
}

.totalVotes.voted {
  color: var(--blue);
}
