.wrapper {
  position: absolute;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px 15px;
  box-sizing: border-box;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.93);
  color: #9a979c;
}

.wrapper > .name {
  display: inline-block;
  margin: -10px 0;
  padding: 10px 0;
  font-size: 2rem;
  font-weight: 600;
}

.ratingsWrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 190px;
}

.ratingRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nameWrapper {
  display: flex;
  z-index: 1;
}

.nameWrapper .icon {
  margin-right: 5px;
  font-size: 1.8rem;
}

.nameWrapper .name {
  max-width: 85px;
  overflow: hidden;
  font-size: 1.3rem;
  font-weight: 500;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.nameWrapper:hover .name {
  overflow: visible;
}

.value {
}
