.icon {
  display: inline-block;
  margin-right: 12px;
}

.buttonWrapper {
  width: 225px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 35px 0 50px auto;
}

.backButton {
  composes: buttonNoStyle from '../../../components/shared/styles/components.module.css';
  color: var(--gray-dark);
  font-size: 1.6rem;
  font-weight: 500;
}