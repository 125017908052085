.wrapper {
  position: relative;
  width: 250px;
  height: 280px;
  margin-right: 40px;
  margin-bottom: 30px;
  overflow: hidden;
  border: 1px solid #e6ebef;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 1px 22px rgba(120, 120, 120, 0.2);
}

.wrapper:nth-child(5n) {
  margin-right: 0;
}

.invisible {
  visibility: hidden;
}
