@import '../styles/colors.module.css';

.wrapper {
  position: relative;
  display: flex;
  align-items: flex-start;
}

.checkboxWrapper {
  position: relative;
}

.checkboxInput {
  position: relative;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}

.checkboxInput + .checkbox {
  position: absolute;
  z-index: 0;
  box-sizing: border-box;
  left: 0;
  background: url('../../../assets/icons/check-blue.svg') no-repeat center;
  background-size: 0 0;
  border: 1px solid var(--blue);
  border-radius: 5px;
  transition: background-size 0.1s, border 0.2s;
}

.checkboxInput,
.checkbox {
  top: 3px;
}

.checkboxInput:checked + .checkbox {
  box-sizing: initial;
  background-size: 12px 9px;
  border-color: var(--white);
}

.label {
  margin-left: 13px;
  color: var(--black);
  font-size: 1.6rem;
  font-weight: 500;
  cursor: pointer;
}
