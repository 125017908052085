@import '../styles/colors.module.css';

.tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tab {
  position: relative;
  margin-right: 30px;
  padding-bottom: 10px;
  color: var(--gray);
  font-size: 1.6rem;
  font-weight: 500;
  text-transform: capitalize;
}

.tab:last-child {
  margin-right: 0;
}

.tab:hover {
  color: var(--black);
}

.tab--active {
  color: var(--black);
}

.tab--active::after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3px;
  background-color: var(--blue);
  border-radius: 3px 3px 0 0;
}
