.content {
  max-width: 600px;
  margin-top: 300px;
}

.stepWrapper {
  max-width: 400px;
  margin: 0 auto 50px;
}

.title {
  composes: subtitle from '../../components/shared/styles/typography.module.css';
  margin-bottom: 40px;
  padding-top: 10px;
}