.wrapper,
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.wrapper {
  z-index: 10;
}

.overlay {
  z-index: 9;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(3px);
  overflow: hidden;
}

.contentWrapper {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  max-width: 100%;
  padding: 50px;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 0 1px 15px #6b6b6b28;
}

.closeButton {
  position: absolute;
  top: 15px;
  right: 15px;
  height: auto;
  padding: 7px;
}

.closeIcon {
}
