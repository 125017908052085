:root {
  --inputWidth: 600px;
}

.textbox {
  max-width: var(--inputWidth);
}

.textbox {
  margin-bottom: 40px;
  color: var(--black);
}

.textarea {
  max-width: var(--inputWidth);
  box-sizing: border-box;
  min-height: 130px;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  margin: 35px 0 50px;
}

.nextButton {
  composes: buttonPrimary from '../../../components/shared/styles/components.module.css';
  width: 135px;
}