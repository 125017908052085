.textbox {
  margin-bottom: 40px;
  color: var(--black);
}

.textbox::placeholder {
  text-transform: lowercase;
}

.backButton {
  composes: buttonNoStyle from '../../../components/shared/styles/components.module.css';
  color: var(--gray-dark);
  font-size: 1.6rem;
  font-weight: 500;
}

.buttonWrapper {
  width: 225px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 35px 0 50px auto;
}

.nextButton {
  composes: buttonPrimary from '../../../components/shared/styles/components.module.css';
  width: 135px;
}