.heroBackground {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 575px;
  background-repeat: no-repeat;
  background-position: top center;
  object-fit: cover;
}
