.wrapper {
  display: flex;
  margin-bottom: 60px;
}

.leftColumn {
  margin-top: 15px;
  margin-right: 22px;
}

.title {
  margin-bottom: 12px;
  color: var(--black);
  font-size: 2.2rem;
  font-weight: 600;
}

.rightColumn > .text {
  margin-bottom: 15px;
  color: var(--purple);
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.7rem;
}
