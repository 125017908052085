@import '../styles/colors.module.css';

:root {
  --circleSize: 24px;
}

.wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 50px;
}

.wrapper::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: calc(var(--circleSize) / 2 - 1px);
  width: calc(100% - var(--circleSize) * 2);
  margin-left: var(--circleSize);
  height: 2px;
  background-color: var(--blue-faded);
}

.stepWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.circleWrapper {
  overflow: hidden;
  width: var(--circleSize);
  height: var(--circleSize);
  box-sizing: border-box;
  background-color: var(--blue-faded);
  border-radius: var(--circleSize);
}

.circleWrapper.active,
.circleWrapper.done {
  border: 2px solid var(--blue);
}

.circleWrapper.done {
  background: var(--blue) url('../../../assets/icons/check-white.svg') no-repeat center;
}

.innerCircle {
  width: 14px;
  height: 14px;
  border: 3px solid var(--white);
  border-radius: 14px;
}

.label {
  color: var(--gray);
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: capitalize;
}
