@import './colors.module.css';

.buttonPrimary {
  min-width: 100px;
  height: 40px;
  padding: 10px 14px;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: var(--blue);
  color: var(--white);
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2rem;
  text-align: center;
  transition: background-color 0.1s;
  cursor: pointer;
}

.buttonPrimary:hover {
  background-color: var(--blue-light);
}

.buttonPrimary:active {
  background-color: var(--blue-dark);
}

.buttonDisabled {
  composes: buttonPrimary;
  background-color: var(--gray-faded);
  border: none;
  color: var(--gray-dark);
}

.buttonDisabled:hover,
.buttonDisabled:active {
  background-color: var(--gray-faded);
  color: var(--gray-dark);
}

.buttonNoStyle {
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
}

.buttonNoStyle:hover,
.buttonNoStyle:active {
  background: none;
}

.buttonPill {
  composes: buttonNoStyle;
  min-width: 30px;
  padding: 2px 10px;
  border: 1px solid var(--blue);
  border-radius: 30px;
}

.questionMarkTooltipLabel {
  position: absolute;
  top: -9px;
  left: 4.3ch;
  padding: 3px;
  color: var(--gray);
  font-size: 1.4rem;
  font-weight: 600;
  cursor: default;
}

.questionMarkTooltip {
  width: 100px;
}
