@import '../../../components/shared/styles/colors.module.css';

:root {
  --pointerWidth: calc(14px / 2);
  --pointerHeight: 6px;
}

.wrapper {
  position: absolute;
  margin-left: 50%;
  padding: 7px 10px;
  z-index: 9;
  background-color: var(--black-light);
  border-radius: 4px;
  color: var(--white);
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.4rem;
  text-align: center;
  transform: translateX(calc(-50% - 1px));
}

.wrapper:first-letter {
  text-transform: capitalize;
}

.top.wrapper {
  bottom: 100%;
  margin-bottom: 8px;
}

.bottom.wrapper {
  top: 100%;
  margin-top: 8px;
}

.bottom::after,
.top::after {
  content: '';
  position: absolute;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -8px;
  border-style: solid;
  -webkit-transform: rotate(360deg);
}

.top::after {
  top: 100%;
  border-width: var(--pointerHeight) var(--pointerWidth) 0 var(--pointerWidth);
  border-color: var(--black-light) transparent transparent transparent;
}

.bottom::after {
  bottom: 100%;
  border-width: 0 var(--pointerWidth) var(--pointerHeight) var(--pointerWidth);
  border-color: transparent transparent var(--black-light) transparent;
}

.hidden {
  visibility: hidden;
}
