@import '../../components/shared/styles/colors.module.css';

.content {
  margin-top: 300px;
}

.tabs {
  max-width: 600px;
  margin: 0 auto;
}

.detailsContent {
  /* max-width: 780px; */
  max-width: 1000px;
  margin: 30px auto;
}

.tabSideNote {
  position: absolute;
  top: -9px;
  left: 100%;
  padding: 3px;
  color: var(--gray);
  font-size: 1.4rem;
  font-weight: 600;
  cursor: default;
}

.tooltip {
  width: 125px;
  text-transform: initial;
}
