.footer {
  display: flex;
  align-items: center;
  height: 40px;
}

.category {
  composes: buttonPill from '../../../components/shared/styles/components.module.css';
  display: flex;
  align-items: center;
  margin-right: 15px;
  text-transform: capitalize;
}

.category .icon {
  margin-right: 4px;
}

.category .text {
  color: var(--blue);
  font-size: 1.2rem;
  font-weight: 500;
}

.verticalDivider {
  width: 1px;
  height: 100%;
  margin-right: 15px;
  background-color: var(--gray-light);
}

.postDate {
  display: flex;
  align-items: center;
  color: var(--gray);
  font-size: 1.2rem;
  font-weight: 500;
  font-style: normal;
}

.circleDivider {
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background-color: var(--gray);
}

.role,
.circleDivider {
  margin-right: 10px;
}

.report {
  composes: buttonNoStyle from '../../../components/shared/styles/components.module.css';
  display: flex;
  align-items: center;
  margin-left: 60px;
  padding: 5px 0;
  color: var(--gray);
  font-size: 1.2rem;
}

.report:hover {
  text-decoration: underline;
}

.reportIcon {
  margin-right: 8px;
}
